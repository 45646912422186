import { useEffect, useState } from "react"
import { lsEncode, lsDecode } from "./utils/localStorage"
import Layout from "./components/Layout"
import Slider from "./components/Slider"
import Popin from "./components/Popin"
import EasterEgg from './components/EasterEgg'
import CalendarStyle from './style'
import Snowfall from 'react-snowfall'
import Note from './assets/images/note.webp'

const snowflake = document.createElement('img')
snowflake.src = Note

const App = () => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    const [welcome, setWelcome] = useState(lsDecode('sevalendar_started') === 'true' ? false : true)
    const [easterEgg, setEasterEgg] = useState(false)
    const [loading, setLoading] = useState(false)
    const [popin, setPopin] = useState(false)
    const [slide, setSlide] = useState(0)
    const [reducedMotion, setReducedMotion] = useState(!mediaQuery.matches)

    const toggleStart = () => {
        lsEncode('sevalendar_started', 'true')
        setLoading(true)

        setTimeout(() => setWelcome(false), 1000)
    }

    useEffect(() => {
        if ( mediaQuery.matches ) {
            setReducedMotion(false)
        }

        mediaQuery.addEventListener('change', () => setReducedMotion(!mediaQuery.matches));
    }, [mediaQuery])

    return (
        <Layout welcome={ welcome } loading={ loading } toggleStart={ toggleStart } reducedMotion={ reducedMotion }>
            { ! welcome ? (
                <CalendarStyle className="calendar">
                    <h1 className="calendar__title">
                        Chaque jour, une astuce green, un fait marquant ou une info qu'on souhaite partager avec vous&nbsp;!
                    </h1>

                    <div className="calendar__slider">
                        <Slider setPopin={ setPopin } setSlide={ setSlide } reducedMotion={ reducedMotion } />
                    </div>

                    <Popin active={ popin } setPopin={ setPopin } slide={ slide }/>
                </CalendarStyle>
            ) : '' }

            <EasterEgg easterEgg={ easterEgg } setEasterEgg={ setEasterEgg } />

            { reducedMotion ? (
                <Snowfall
                    radius={easterEgg ? [5, 15] : [0.5, 3.0]}
                    images={easterEgg ? [snowflake] : false}
                />
            ) : '' }
        </Layout>
    )
}

export default App

import styled from 'styled-components';
import { colors } from '../../assets/vars';
import { breakpoint } from '../../assets/mixins';

const InfosStyle = styled.div`
    // Deployer
    .infos-deployer {
        position: relative;

        .btn {
            &:hover {
                & + svg {
                    transform: rotate(8deg);
                }
            }
        }

        svg {
            position: absolute;
            top: -22px;
            right: -30px;
            width: 54px;
            transition: transform .3s;
            pointer-events: none;

            ${breakpoint.s`
                width: 40px;
                top: -16px;
                right: -20px;
            `}
        }
    }

    // Popin
    .infos-popin {
        display: flex;
        flex-flow: column;
        position: fixed;
        z-index: 5;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 35vw;
        min-width: 400px;
        font-size: 2rem;
        transform: translateX(${p => p.$active ? 0 : '-100%'});
        transition: transform .3s;
        background-color: ${colors.observatory};
        border-right: 1px solid ${colors.white};
        font-weight: 400;

        button {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            align-items: center;
            justify-content: center;
            width: 4vw;
            height: 4vw;
            min-width: 50px;
            min-height: 50px;
            border-left: 1px solid ${colors.white};
            border-bottom: 1px solid ${colors.white};
            overflow: hidden;

            svg {
                width: 40%;
                height: 40%;
                transition: fill .2s;
                fill: ${colors.white};

                ${breakpoint.s`
                    width: 18px;
                    height: 18px;
                `}
            }

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                inset: -1px;
                transition: transform .3s;
                transform: translateX(-100%);
                background-color: ${colors.white};
            }

            &:hover {
                svg {
                    fill: ${colors.sherwood};
                }

                &::before {
                    transform: translateX(0);
                }
            }
        }

        h2 {
            margin-bottom: 30px;
            padding: 6vw 4vw 0 4vw;
            font-size: 6rem;
            font-weight: 400;
            font-style: italic;
            text-transform: uppercase;

            ${breakpoint.s`
                font-size: 4rem;
                margin-bottom: 20px;
            `}
        }

        &__content {
            position: relative;
            flex: 1;
            padding: 0 4vw 2.5vw;
            overflow-x: auto;
        }

        p {
            & + p {
                margin-top: 1em;
            }
        }

        &.is-active {
            transform: translateX(0);
        }

        ${breakpoint.s`
            min-width: 100%;
            border-right: 0;
            padding-top: 40px;
            font-size: 1.7rem;
        `}
    }

    &::after {
        content: '';
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        bottom: 0;
        right: 20%;
        transition: opacity .3s;
        opacity: ${p => p.$active ? 1 : 0};
        background: linear-gradient(to right, ${colors.sherwood}, transparent);
        pointer-events: none;
    }

    .socials {
        display: flex;
        position: relative;
        flex-flow: row wrap;
        align-items: center;
        border-top: 1px solid ${colors.white};

        &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            height: 2.5vw;
            transform: translateY(-100%);
            background: linear-gradient(to bottom, transparent, ${colors.eucalyptus});
            pointer-events: none;
        }

        a {
            flex: 1;
            padding: 20px;

            & + a {
                border-left: 1px solid ${colors.white};
            }

            &:hover {
                svg {
                    transform: scale(1.1);
                }
            }
        }

        svg {
            display: block;
            width: 20px;
            height: 20px;
            margin: 0 auto;
            transition: transform .3s;
            fill: ${colors.white};
        }
    }
`;

export default InfosStyle

import styled from 'styled-components';
import { breakpoint } from './assets/mixins';

const CalendarStyle = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;
    opacity: 0;
    padding: 40px 0;
    animation: fadeIn .5s forwards;
    height: calc(100vh - 200px);

    .calendar {
        &__title {
            max-width: 640px;
            font-size: 2rem;
            font-weight: 700;
            text-transform: uppercase;
            align-self: flex-start;
            padding-left: 40px;

            ${breakpoint.s`
                padding-left: 20px;
                font-size: 1.4rem;
            `}
        }

        &__slider {
            width: 100%;
            height: 100%;
            padding: 0 10%;
            overflow: hidden;
        }
    }

    .notice {
        text-align: center;
        font-family: "Merriweather", sans-serif;
        font-size: 3rem;
    }

    ${breakpoint.s`
        padding: 0;
        height: calc(100vh - 186px);
    `}

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export default CalendarStyle

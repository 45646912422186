import styled from 'styled-components';
import { colors } from '../../assets/vars';
import { breakpoint } from '../../assets/mixins';

const HeaderStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 40px;

    & > div {
        display: flex;
        flex: 1;
        gap: 20px;
        align-items: center;

        &.left {
            ${breakpoint.s`
                order: 3;
            `}
        }

        &.center {
            justify-content: center;

            a {
                transition: opacity .25s;

                &:hover {
                    opacity: .7;
                }
            }

            svg {
                display: block;
                height: 40px;
                fill: ${colors.white};
            }

            ${breakpoint.s`
                flex: 0 1 100%;
                order: 1;
            `}
        }

        &.right {
            justify-content: flex-end;

            ${breakpoint.s`
                order: 2;
            `}
        }
    }

    ${breakpoint.s`
        gap: 20px;
        padding: 22px 20px;
        gap: 10px 20px;
    `}
`;

export default HeaderStyle

export const colors = {
    white:       '#fff',
    black:       '#000',
    observatory: '#038c5a',
    eucalyptus:  '#208d63',
    sherwood:    '#014029',
    evening:     '#025235',
    orange:      '#f5ab00',
    monza:       '#e0001d',
}

import styled from 'styled-components';
import { colors } from '../../assets/vars';
import { breakpoint } from '../../assets/mixins';

const SliderStyle = styled.div`
    width: 60%;
    height: 100%;
    margin-left: auto;
    counter-reset: slideCount;

    .swiper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: visible;

        .swiper-wrapper {
            margin: auto 0;
            height: auto;

            .swiper-slide {
                aspect-ratio: 58 / 77;
                height: auto;
                counter-increment: slideCount;

                &.christmas-card {
                    .inner {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        height: 100%;
                        gap: 12%;
                        align-items: center;
                        padding: 15% 0;
                        transition: transform .35s, background-color .25s;
                        transform-origin: left center;
                        background-color: ${colors.evening};
                        border: 1px dashed ${colors.white};
                        border-left: 0;
                        box-sizing: border-box;
                        text-align: center;
                        will-change: transform;
                        cursor: pointer;

                        .crown {
                            position: relative;
                            width: 70%;

                            svg {
                                display: block;
                                fill: ${colors.white};
                            }

                            &::before {
                                content: counter(slideCount, decimal-leading-zero);
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 3vw;
                                font-family: 'Merriweather', sans-serif;
                                font-weight: 700;

                                ${breakpoint.s`
                                    font-size: 4rem;
                                `}
                            }
                        }

                        p {
                            flex: 1;
                            padding: 0 10px;
                            font-size: 1.4vw;
                            font-weight: 700;
                            font-style: italic;
                            text-transform: uppercase;

                            ${breakpoint.s`
                                font-size: 1.8rem;
                            `}
                        }
                    }

                    &.is-visited {
                        .inner {
                            transform: perspective(1000px) rotateY(-40deg);
                            background-color: ${colors.eucalyptus};
                            border-style: solid;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: -2;
                        inset: 1px;
                        border: 1px solid ${colors.white};
                        background-color: ${colors.eucalyptus};
                    }

                    .lighting {
                        position: absolute;
                        z-index: -1;
                        inset: -8vw -8vw -8vw 85%;
                        transition: opacity .35s;
                        opacity: 0;
                        pointer-events: none;

                        &::before {
                            content: '';
                            position: absolute;
                            inset: 0;
                            animation: lighting 3s infinite;
                            background: radial-gradient(ellipse at left, #f2e9a2, transparent, transparent);
                        }
                    }

                    &:hover {
                        .inner {
                            transform: perspective(1000px) rotateY(-40deg);
                        }

                        /* &:not(.is-visited) { */
                            .lighting {
                                opacity: 1;
                                transition: opacity .35s .2s;
                            }
                        /* } */
                    }
                }

                &.soon {
                    background-color: ${colors.evening};
                    border: 1px dashed ${colors.white};
                    border-left: 0;
                    cursor: default;

                    .lutins {
                        position: absolute;
                        bottom: 0;
                        left: 5%;
                        width: 90%;

                        svg {
                            display: block;
                        }

                        &::before {
                            content: counter(slideCount, decimal-leading-zero);
                            position: absolute;
                            top: 35%;
                            right: 25%;
                            font-size: 3vw;
                            transform: rotate(20deg);
                            font-family: 'Merriweather', sans-serif;
                            font-weight: 700;
                            animation: floating 4s infinite;
                            will-change: transform;

                            ${breakpoint.s`
                                font-size: 4rem;
                            `}
                        }
                    }
                }
            }
        }

        .swiper-pagination {
            position: fixed;
            bottom: 60px;
            left: 50%;
            display: flex;
            gap: 17px;
            transform: translateX(-50%);

            &-bullet {
                display: block;
                height: 20px;
                width: 0;
                transition: width .3s, transform .3s;
                border: 1px solid ${colors.white};
                border-radius: 2px;
                cursor: pointer;
                transform: scaleX(.5);

                &-active {
                    width: 20px;
                    transform: scaleX(1);

                    ${breakpoint.s`
                        width: 16px;
                    `}
                }

                &:hover {
                    transform: scaleX(1);
                }

                ${breakpoint.s`
                    height: 16px;
                `}
            }

            ${breakpoint.s`
                bottom: 20px;
                gap: 12px;
            `}
        }
    }

    ${breakpoint.s`
        width: 80%;
        margin-left: 0;
        margin: 0 auto;
    `}
`;

export default SliderStyle

import styled from 'styled-components';

const EasterEggStyle = styled.div`
    position: fixed;
    z-index: 100;
    inset: 0;
    pointer-events: none;

    .santa {
        position: absolute;
        top: ${p => p.$active ? '-60px' : '-3000px'};
        left: 30%;
        height: 60%;
        transition: top 2s .5s;
        transform-origin: top center;
        animation: santa 2s infinite ease-in-out;
    }

    .mariah {
        position: absolute;
        bottom: 0;
        width: 15vw;
        right: 0;
        transition: opacity 20s;
        opacity: ${p => p.$active ? '.3' : '0'};
    }

    @keyframes santa {
        0%, 100% {
            transform: translateX(-50%) rotate(20deg);
        }

        50% {
            transform: translateX(-50%) rotate(-20deg);
        }
    }
`;

export default EasterEggStyle

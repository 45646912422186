import Infos from "../Infos"
import Letter from "../Letter"
import HeaderStyle from "./style"
import { ReactComponent as Logo } from '../../assets/svgs/logo.svg';

const Header = () => (
    <HeaderStyle>
        <div className="left">
            <Infos />
        </div>

        <div className="center">
            <a href="https://sevanova.com/" target="_blank" rel="noopenner noreferrer nofollow">
                <Logo />
            </a>
        </div>

        <div className="right">
            { window.innerWidth > 767 ? (
                <a className="btn" href="https://sevanova.com/" target="_blank" rel="noopenner noreferrer nofollow">
                    Site&nbsp;<span>sevanova.com</span>
                </a>
            ) : '' }

            <Letter />
        </div>
    </HeaderStyle>
)

export default Header

export const lsDecode = (key, number) => {
    return localStorage.getItem(key) ? number ? Number(atob(localStorage.getItem(key))) : atob(localStorage.getItem(key)) : null
}

export const lsEncode = (key, value) => {
    return localStorage.setItem(key, btoa(value))
}

export const lsRemove = (key) => {
    return localStorage.removeItem(key)
}

import { createGlobalStyle } from "styled-components"
import { colors } from './vars'
import { breakpoint } from './mixins'
import Background from './images/background.webp'

const RulesStyles = createGlobalStyle`
    body {
        position: relative;
        isolation: isolate;
        background: url(${Background});
        background-size: cover;
        color: ${colors.white};
        font-family: 'Canaro', sans-serif;
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 300;
        overflow: hidden;

        ${breakpoint.s`
            font-size: 1.7rem;
        `}
    }

    .btn {
        position: relative;
        isolation: isolate;
        display: inline-flex;
        align-items: center;
        height: 40px;
        padding: 0 20px;
        transition: color .2s;
        border: 1px solid ${colors.white};
        border-radius: 40px;
        background-color: ${colors.observatory};
        color: ${colors.white};
        font-size: 1.5rem;
        font-weight: 400;
        overflow: hidden;

        svg {
            width: 25px;
            height: 25px;
            transition: fill .2s;
            fill: ${colors.white};
        }

        span {
            font-weight: 700;
            font-style: italic;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            inset: -1px;
            transition: transform .25s;
            background-color: ${colors.white};
            border-radius: 40px;
            transform: translateX(-100%);
        }

        &:hover {
            color: ${colors.sherwood};

            svg {
                fill: ${colors.sherwood};
            }

            &::before {
                transform: translateX(0);
            }
        }

        &--orange {
            background-color: ${colors.orange};
        }

        ${breakpoint.s`
            order: 1;
            height: 34px;
        `}
    }

    // Toastify
    .Toastify {
        &__toast {
            border-radius: 0;
            border: 1px solid #fff;
        }

        &__toast-icon {
            svg {
                fill: ${colors.eucalyptus};
            }
        }

        &__toast-body {
            div {
                font-family: "Canaro", sans-serif;
                font-weight: 400;
                font-size: 1.7rem;
                line-height: 1.1;
                color: ${colors.sherwood};
            }
        }

        &__progress-bar {
            &--success {
                background-color: ${colors.eucalyptus};
            }
        }
    }

    // Animations
    @keyframes floating {
        0%,100% {
            transform: rotate(20deg);
        }

        50% {
            transform: rotate(30deg);
        }
    }

    @keyframes lighting {
        0%,100% {
            opacity: .7;
        }

        50% {
            opacity: 1;
        }
    }
`;

export default RulesStyles;

import { useState } from "react"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import LetterStyle from "./style"
import { ReactComponent as Mail } from '../../assets/svgs/mail.svg';
import { ReactComponent as Cross } from '../../assets/svgs/cross.svg';
import { ReactComponent as Stamp } from '../../assets/svgs/stamp.svg';
import { ReactComponent as Waves } from '../../assets/svgs/waves.svg';

const Letter = () => {
    const [active, setActive] = useState(false)
    const [lastname, setLastname] = useState('')
    const [firstname, setFirstname] = useState('')
    const [society, setSociety] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const letterSubmit = (e) => {
        e.preventDefault()

        axios.post(`https://api.structuro.fr/wp-json/frm/v2/forms/5/entries`, {
            avent_lastname: lastname,
            avent_firstname: firstname,
            avent_society: society,
            avent_email: email,
            avent_message: message
        }, {
            headers: {
                Authorization: `Basic ${btoa(`${process.env.REACT_APP_FORMIDABLE_API_KEY}:x`)}`,
            },
        }).then(res => {
            setActive(false)

            setTimeout(() => {
                toast.success( 'Votre message nous est bien parvenu !', {
                    position: "top-right",
                    autoClose: 5000,
                    draggable: true,
                    theme: "light",
                    pauseOnHover: true,
                    closeOnClick: true,
                    closeButton: false,
                })
                setLastname('')
                setFirstname('')
                setSociety('')
                setEmail('')
                setMessage('')
            }, 500)
        }).catch(err => console.log(err))
    }

    return (
        <LetterStyle $active={ active }>
            <button className="btn" onClick={() => setActive(true)}>
                <Mail />
            </button>

            <div className="letter-popin">
                <button className="letter-popin__close" onClick={() => setActive(false)}>
                    <Cross />
                </button>

                <form onSubmit={(e) => letterSubmit(e)}>
                    <div className="left">
                        <textarea placeholder="Message" value={ message } onChange={(e) => setMessage(e.target.value)} />
                    </div>

                    <div className="right">
                        <div className="submit-container">
                            <Waves className="waves"/>

                            <button type="submit">
                                <span>Envoyer</span>
                                <Stamp />
                            </button>
                        </div>

                        <input type="text" placeholder="Nom*" value={ lastname } onChange={(e) => setLastname(e.target.value)} required />
                        <input type="text" placeholder="Prénom*" value={ firstname } onChange={(e) => setFirstname(e.target.value)} required />
                        <input type="text" placeholder="Société*" value={ society } onChange={(e) => setSociety(e.target.value)} required />
                        <input type="email" placeholder="Email*" value={ email } onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                </form>
            </div>

            <ToastContainer />
        </LetterStyle>
    )
}

export default Letter

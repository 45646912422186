import EasterEggStyle from './style'
import Santa from '../../assets/images/santa.webp'
import Mariah from '../../assets/images/mariah.webp'

const EasterEgg = ({ easterEgg, setEasterEgg }) => {
    if (window.addEventListener) {
        const code = '78,79,69,76';
        let kkeys = [];

        window.addEventListener('keydown', (e) => {
            kkeys.push(e.keyCode);

            if (kkeys.toString().indexOf(code) >= 0) {
                const mariahMP3 = new Audio()
                mariahMP3.src = "https://ia803101.us.archive.org/0/items/mariahcareyalliwantforchristmasisyou_201912/Mariah%20Carey%20-%20All%20I%20Want%20For%20Christmas%20Is%20You.mp3"

                setEasterEgg(true)
                mariahMP3.play()
                kkeys = [];
            }
        }, true);
    }

    return (
        <EasterEggStyle $active={ easterEgg }>
            <img className="santa" src={ Santa } alt="" />

            <img className="mariah" src={ Mariah } alt="" />
        </EasterEggStyle>
    )
}

export default EasterEgg

import { createGlobalStyle } from "styled-components"

// Canaro
import CanaroBoldWoff2 from "./fonts/Canaro-Bold.woff2";
import CanaroBoldWoff from "./fonts/Canaro-Bold.woff";
import CanaroBoldTtf from "./fonts/Canaro-Bold.ttf";

import CanaroBookWoff2 from "./fonts/Canaro-Book.woff2";
import CanaroBookWoff from "./fonts/Canaro-Book.woff";
import CanaroBookTtf from "./fonts/Canaro-Book.ttf";

import CanaroLightWoff2 from "./fonts/Canaro-Light.woff2";
import CanaroLightWoff from "./fonts/Canaro-Light.woff";
import CanaroLightTtf from "./fonts/Canaro-Light.ttf";

// Merriweather
import MerriweatherBoldWoff2 from "./fonts/Merriweather-Bold.woff2";
import MerriweatherBoldWoff from "./fonts/Merriweather-Bold.woff";
import MerriweatherBoldTtf from "./fonts/Merriweather-Bold.ttf";

const FontStyles = createGlobalStyle`
    // Canaro
    @font-face {
        font-family: "Canaro";
        src: url(${CanaroBoldWoff2}) format("woff2"),
             url(${CanaroBoldWoff}) format("woff"),
             url(${CanaroBoldTtf}) format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Canaro";
        src: url(${CanaroBookWoff2}) format("woff2"),
             url(${CanaroBookWoff}) format("woff"),
             url(${CanaroBookTtf}) format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Canaro";
        src: url(${CanaroLightWoff2}) format("woff2"),
             url(${CanaroLightWoff}) format("woff"),
             url(${CanaroLightTtf}) format("truetype");
        font-weight: 300;
        font-style: normal;
    }

    // Merriweather
    @font-face {
        font-family: "Merriweather";
        src: url(${MerriweatherBoldWoff2}) format("woff2"),
             url(${MerriweatherBoldWoff}) format("woff"),
             url(${MerriweatherBoldTtf}) format("truetype");
        font-weight: 700;
        font-style: normal;
    }
`;

export default FontStyles;

import { useState } from "react"
import InfosStyle from "./style"
import { ReactComponent as Hat } from '../../assets/svgs/hat.svg';
import { ReactComponent as Cross } from '../../assets/svgs/cross.svg';
import { ReactComponent as Insta } from '../../assets/svgs/instagram.svg';
import { ReactComponent as Linkedin } from '../../assets/svgs/linkedin.svg';

const Infos = () => {
    const [active, setActive] = useState(false)

    return (
        <InfosStyle $active={ active }>
            <div className="infos-deployer">
                <button className="btn btn--orange" onClick={() => setActive(true)}>
                    Infos
                </button>

                <Hat />
            </div>

            <div className="infos-popin">
                <button onClick={() => setActive(false)}>
                    <Cross />
                </button>

                <h2>Infos</h2>

                <div className="infos-popin__content">
                    <p>
                        Cher ami, cher client, cher partenaire, cher collaborateur… bref cher visiteur,
                    </p>
                    <p>
                        Bienvenue sur le calendrier de l'Avent de l'agence de communication SEVANOVA !
                        Une agence qui ne prétend pas encore à être vertueuse mais qui cherche à le devenir par l'impulsion et l'engagement de ses dirigeants et de ses collaborateurs.
                    </p>
                    <p>
                        Notre vocation est d'accompagner nos clients sur les sujets RH, de com interne, de com externe et marketing en exploitant tous les outils de la communication (web, print, vidéo, événementiel…) de manière pertinente et avec une réelle conscience des enjeux environnementaux.
                    </p>
                    <p>
                        Quotidiennement, nous cherchons à améliorer nos process, nos solutions, en cherchant astuces et bonnes pratiques que nous intégrons dans nos réponses aux consultations autant que pour nous même.
                    </p>
                    <p>Nous sommes heureux de partager ici quelques-unes de ces solutions !</p>
                    <p>
                        N'hésitez pas à nous écrire sur le site ou à commenter sur les réseaux pour enrichir ou questionner.<br />
                        N'hésitez pas à partager pour sensibiliser !<br />
                        N'hésitez pas à nous consulter !<br />
                        N'hésitez pas à nous suivre sur les réseaux sociaux ⤵️
                    </p>
                    <p>Très belles fêtes à tous !</p>
                </div>

                <div className="socials">
                    <a href="https://www.instagram.com/agence_sevanova/" target="_blank" title="Suivez-nous sur Insta" rel="noopener noreferrer">
                        <Insta />
                    </a>
                    <a href="https://www.linkedin.com/company/sevanova/" target="_blank" title="Suivez-nous sur LinkedIn" rel="noopener noreferrer">
                        <Linkedin />
                    </a>
                </div>
            </div>
        </InfosStyle>
    )
}

export default Infos

import styled from 'styled-components';
import { breakpoint } from '../../assets/mixins';

const LayoutStyle = styled.div`
    .grid {
        display: grid;
        position: fixed;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100vh;
        grid-template-columns: 1fr 1fr 140px .7fr 2fr .55fr 1.3fr;
        grid-template-rows: 120px 2fr 1fr 1fr;
        overflow: hidden;

        &.is-fixed {

        }

        ${breakpoint.s`
            grid-template-columns: 20px 1fr 20px;
            grid-template-rows: 130px 1fr 90px;
            height: auto;
        `}
    }

    .grid-line {
        border-width: 1px;
        border-color: white;
        opacity: .5;
    }

    .grid-line-1 {
        grid-column: 1;
        grid-row: 1 / 3;
        border-right-style: solid;
    }

    .grid-line-2 {
        grid-column: 1;
        grid-row: 3;
        border-style: solid;
        border-left: 0;
    }

    .grid-line-3 {
        grid-column: 1;
        grid-row: 4;
        border-right-style: solid;
    }

    .grid-line-4 {
        grid-column: 5 / 7;
        grid-row: 2;
        border-top-style: solid;
        border-right-style: solid;
    }

    .grid-line-5 {
        grid-column: 2 / 6;
        grid-row: 3;
        border-top-style: solid;
    }

    .grid-line-6 {
        grid-column: 2;
        grid-row: 3;
        border-bottom-style: solid;
    }

    .grid-line-7 {
        grid-column: 4 / 7;
        grid-row: 3;
        border-bottom-style: solid;
        border-right-style: solid;
    }

    .grid-line-8 {
        grid-column: 6;
        grid-row: 4;
        border-right-style: solid;
    }

    .grid-line-9 {
        grid-column: 7;
        grid-row: 2/4;
        width: 1px;
        height: 100%;
        transform-origin: bottom left;
        transform: rotate(-35deg) scaleY(1.22);
        background-color: white;
    }

    .holly {
        grid-column: 5;
        grid-row: 2;
        transition: opacity 1s;
        opacity: ${p => p.$loading ? 0 : 1};

        svg {
            display: block;
            width: 50%;
            margin: 0 auto;

            ${breakpoint.s`
                width: 160px;
                margin: 0 0 0 auto;
                transform: rotate(20deg);
            `}
        }

        ${breakpoint.s`
            grid-row: 2;
            grid-column: 2;
            margin-top: 40px;
        `}
    }

    .candy {
        display: flex;
        grid-column: 1;
        grid-row: 2 / 5;
        align-self: end;
        transition: transform .5s ease-in-out;
        transform: translateX(${p => p.$loading ? '-110%' : 0}) rotateY(180deg);
        will-change: transform;
    }

    .balls {
        position: relative;
        grid-column: 7;
        grid-row: 2 / 5;

        .ball {
            position: absolute;
            bottom: ${p => p.$loading ? 'calc(100% + 120px)' : p.$welcome ? '100%' : 'calc(100% + 120px)'};
            left: 0;
            right: 0;
            transition: bottom 1s ease-in-out, transform 1s ease-in-out;
            transform-origin: bottom center;
            will-change: transform;

            svg {
                display: block;
            }

            &--1 {
                transform: translateY(100%);

                &:hover {
                    transform: translate(10px, 100%) rotate(5deg);
                }
            }

            &--2 {
                transform: translateY(${p => p.$loading ? '100%' : p.$welcome ? '0' : '100%'});
                bottom: ${p => p.$loading ? 'calc(100% - 40px)' : p.$welcome ? 0 : 'calc(100% - 40px)'};

                &:hover {
                    transform: translate(10px, ${p => p.$loading ? '100%' : p.$welcome ? '0' : '100%'}) rotate(5deg);
                }
            }
        }
    }

    .intro-text {
        z-index: 1;
        place-self: end;
        grid-column: 2 / 7;
        grid-row: 2 / 4;
        transition: opacity 1s;
        opacity: ${p => p.$loading ? 0 : 1};
        color: white;
        font-size: clamp(2.6rem, 3.7vw, 6.5rem);
        font-weight: 700;
        text-align: right;
        text-transform: uppercase;

        span {
            display: block;

            ${breakpoint.s`
                display: inline;
            `}
        }

        ${breakpoint.s`
            grid-column: 2;
            grid-row: 2;
            place-self: center;
            text-align: left;
        `}
    }

    .bouli-junior {
        position: relative;
        grid-row: 1;
        grid-column: 2;
        place-self: end;
        transform: rotate(-37deg);
        will-change: transform;

        svg {
            display: block;
            width: 120px;
        }
    }

    .bouli {
        position: relative;
        grid-row: 3;
        grid-column: 2;
        place-self: end;
        top: ${p => p.$loading ? '500px' : 0};
        opacity: ${p => p.$loading ? 0 : 1};
        transform: translateX(${p => p.$loading ? '120px' : 0}) rotate(${p => p.$loading ? '50' : 0}deg);
        transition: top .4s .2s linear, transform .6s, opacity .4s .3s;
        will-change: transform;

        svg {
            display: block;
            width: 120px;

            ${breakpoint.s`
                width: 80px;
            `}
        }

        ${breakpoint.s`
            grid-row: 2;
            place-self: start;
        `}
    }

    .gift {
        grid-row: 4;
        grid-column: 2;
        align-self: end;
        transition: transform .5s ease-in-out;
        transform: translateY(${p => p.$loading ? '110%' : 0});
        will-change: transform;

        svg {
            display: block;
            width: 10vw;
            height: auto;
            transform: translate(-50%, 20%);

            ${breakpoint.s`
                width: 100px;
                align-self: start;
                transform: translate(-15%, 0);
            `}
        }

        ${breakpoint.s`
            grid-row: 3;
            grid-column: 2;
            place-self: start;
        `}
    }

    .deer {
        grid-row: 4;
        grid-column: 3;
        align-self: end;
        transition: transform 5s;
        transform: translateX(${p => p.$welcome ? '40%' : '-220%'});

        svg {
            display: block;

            ${breakpoint.s`
                height: 200px !important;
            `}
        }

        ${breakpoint.s`
            grid-row: 3;
            grid-column: 2;
        `}
    }

    .luge {
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        left: 0;

        svg {
            display: block;
        }

        ${breakpoint.s`
            width: 250vw;
        `}
    }

    .button-wrapper {
        grid-column: 1 / -1;
        grid-row: 4;
        place-self: center;
        text-align: center;
        transition: opacity 1s;
        opacity: ${p => p.$loading ? 0 : 1};
        pointer-events: ${p => p.$loading ? 'none' : 'all'};

        ${breakpoint.s`
            grid-column: 2;
            grid-row: 3;
        `}
    }
`;

export default LayoutStyle

import styled from 'styled-components';
import { colors } from '../../assets/vars';
import { breakpoint } from '../../assets/mixins';

const LetterStyle = styled.div`
    .letter-popin {
        position: fixed;
        z-index: 5;
        top: 2.5vw;
        right: 3.5vw;
        padding: 6vw 4vw;
        border: 1px solid ${colors.sherwood};
        transition: transform .5s cubic-bezier(.3, 1.4, .5, 1), opacity .25s;
        transform: translateY(${p => p.$active ? '0' : '-100%'}) rotate(${p => p.$active ? '5deg' : '40deg'});
        opacity: ${p => p.$active ? 1 : 0};
        color: ${colors.sherwood};
        background-color: ${colors.white};
        font-size: 2rem;
        font-weight: 300;
        font-style: italic;
        pointer-events: ${p => p.$active ? 'all' : 'none'};

        form {
            display: flex;
            gap: 4vw;

            .left {
                border-right: 1px solid ${colors.sherwood};

                ${breakpoint.s`
                    flex: 1;
                    border-right: 0;
                    border-top: 1px solid ${colors.sherwood};
                `}
            }

            input {
                display: block;
                padding: 18px 0;
                border: 0;
                width: 17vw;
                min-width: 250px;
                color: ${colors.sherwood};
                font-size: 2rem;
                font-weight: 300;
                font-style: italic;
                outline: none;

                &::placeholder {
                    opacity: .5;
                    color: ${colors.sherwood};
                }

                & + input {
                    border-top: 1px solid ${colors.sherwood};
                }

                ${breakpoint.s`
                    width: 100%;
                    min-width: 0;
                    padding: 14px 0;
                    font-size: 1.7rem;
                `}
            }

            textarea {
                display: block;
                height: 100%;
                padding-right: 4vw;
                width: 21vw;
                min-width: 300px;
                border: 0;
                color: ${colors.sherwood};
                font-size: 2rem;
                font-weight: 300;
                font-style: italic;
                resize: none;
                outline: none;

                &::placeholder {
                    opacity: .5;
                    color: ${colors.sherwood};
                }

                ${breakpoint.s`
                    width: 100%;
                    min-width: 0;
                    margin-top: 14px;
                    font-size: 1.7rem;
                `}
            }

            .submit-container {
                position: relative;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 2.5vw;

                button {
                    position: relative;

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: color .25s;
                        color: ${colors.sherwood};
                        font-size: 1.5rem;
                        font-weight: 400;

                        ${breakpoint.s`
                            font-size: 1.4rem;
                        `}
                    }

                    svg {
                        width: 100px;

                        path {
                            transition: fill .25s;
                        }

                        ${breakpoint.s`
                            width: 80px;
                        `}
                    }

                    &:hover {
                        span {
                            color: ${colors.white};
                        }

                        svg {
                            path {
                                fill: ${colors.monza} !important;
                            }
                        }
                    }
                }

                .waves {
                    position: absolute;
                    z-index: 1;
                    bottom: -2vw;
                    left: 50%;
                    height: 80px;
                    fill: ${colors.sherwood};
                    transform: translateX(-50%) rotate(3deg);
                    pointer-events: none;

                    ${breakpoint.s`
                        height: 60px;
                    `}
                }

                ${breakpoint.s`
                    margin-bottom: 0;
                `}
            }

            ${breakpoint.s`
                flex-direction: column-reverse;
                gap: 0;
                height: 100%;
            `}
        }

        &__close {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            align-items: center;
            justify-content: center;
            width: 4vw;
            height: 4vw;
            min-width: 50px;
            min-height: 50px;
            border-left: 1px solid ${colors.sherwood};
            border-bottom: 1px solid ${colors.sherwood};
            overflow: hidden;

            svg {
                width: 40%;
                height: 40%;
                transition: fill .2s;
                fill: ${colors.sherwood};

                ${breakpoint.s`
                    width: 18px;
                    height: 18px;
                `}
            }

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                inset: -1px;
                transition: transform .25s;
                transform: translateX(-100%);
                background-color: ${colors.sherwood};
            }

            &:hover {
                svg {
                    fill: ${colors.white};
                }

                &::before {
                    transform: translateX(0);
                }
            }
        }

        ${breakpoint.s`
            inset: 20px;
            padding: 80px 20px 20px;
        `}
    }

    &::after {
        content: '';
        position: fixed;
        z-index: 4;
        top: 0;
        left: 20%;
        bottom: 0;
        right: 0;
        transition: opacity .25s;
        opacity: ${p => p.$active ? 1 : 0};
        background: linear-gradient(to right, transparent, ${colors.sherwood});
        pointer-events: ${p => p.$active ? 'all' : 'none'};
    }
`;

export default LetterStyle

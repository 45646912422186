import { ReactComponent as DarkMode } from '../assets/slides/dark-mode.svg';
import { ReactComponent as ReusableStand } from '../assets/slides/reusable-stand.svg';
import { ReactComponent as Ecoconception } from '../assets/slides/eco-conception.svg';
import { ReactComponent as Typography } from '../assets/slides/typography.svg';
import { ReactComponent as GreenHost } from '../assets/slides/green-host.svg';
import { ReactComponent as LocalPartners } from '../assets/slides/local-partners.svg';
import { ReactComponent as Goodies } from '../assets/slides/goodies.svg';
import { ReactComponent as Sensitize } from '../assets/slides/sensitize.svg';
import { ReactComponent as DepollutingPlants } from '../assets/slides/depolluting-plants.svg';
import { ReactComponent as Print } from '../assets/slides/print.svg';
import { ReactComponent as VideoCompress } from '../assets/slides/video-compress.svg';
import { ReactComponent as CarbonPrint } from '../assets/slides/carbon-print.svg';
import { ReactComponent as Computers } from '../assets/slides/computers.svg';
import { ReactComponent as Colors } from '../assets/slides/colors.svg';
import { ReactComponent as WebdesignEco } from '../assets/slides/webdesign-eco.svg';
import { ReactComponent as Paper } from '../assets/slides/paper.svg';
import { ReactComponent as Eevent } from '../assets/slides/e-event.svg';
import { ReactComponent as Css } from '../assets/slides/css.svg';
import { ReactComponent as UpdateWeb } from '../assets/slides/update-web.svg';
import { ReactComponent as AuditWeb } from '../assets/slides/audit-web.svg';
import { ReactComponent as FiveR } from '../assets/slides/5r.svg';
import { ReactComponent as Certifs } from '../assets/slides/certifs.svg';
import { ReactComponent as GreenLearning } from '../assets/slides/green-learning.svg';
import { ReactComponent as Christmas } from '../assets/slides/christmas.svg';

const slideImages = {
    'dark-mode': <DarkMode />,
    'reusable-stand': <ReusableStand />,
    'eco-conception': <Ecoconception />,
    'typography': <Typography />,
    'green-host': <GreenHost />,
    'local-partners': <LocalPartners />,
    'goodies': <Goodies />,
    'sensitize': <Sensitize />,
    'depolluting-plants': <DepollutingPlants />,
    'print': <Print />,
    'video-compress': <VideoCompress />,
    'carbon-print': <CarbonPrint />,
    'computers': <Computers />,
    'colors': <Colors />,
    'webdesign-eco': <WebdesignEco />,
    'paper': <Paper />,
    'e-event': <Eevent />,
    'css': <Css />,
    'update-web': <UpdateWeb />,
    'audit-web': <AuditWeb />,
    '5r': <FiveR />,
    'certifs': <Certifs />,
    'green-learning': <GreenLearning />,
    'christmas': <Christmas />,
}

export default slideImages

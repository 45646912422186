import PopinStyle from "./style"
import data from "../../data.json"
import slideImages from '../../utils/slideImages'
import { ReactComponent as Cross } from '../../assets/svgs/cross.svg';
import { ReactComponent as Wave } from '../../assets/svgs/wave.svg';

const Popin = ({ active, setPopin, slide }) => {
    const desktop = window.innerWidth > 767

    return (
        <PopinStyle $active={ active }>
            <div className="inner">
                <div className="left">
                    { desktop ? (
                        <div className="space"></div>
                    ) : '' }

                    <figure>
                        { slideImages[data?.[slide]?.image] }
                    </figure>
                </div>

                <div className="right">
                    <div className="number">
                        { String(slide + 1).padStart(2, '0') }
                    </div>

                    <div className="title">
                        <h2>{ data?.[slide]?.popin_title }</h2>
                    </div>

                    <div className="description">
                        <p dangerouslySetInnerHTML={{ __html: data?.[slide]?.description }} />

                        { data?.[slide]?.buttons ? (
                            <div className="description__buttons">
                                { data[slide].buttons.map((item, index) => (
                                    <a key={ index } className="btn" href={ item?.url } target="_blank" rel="noopenner noreferrer nofollow">
                                        { item?.label }
                                    </a>
                                )) }
                            </div>
                        ) : '' }
                    </div>
                </div>

                <button className="close" onClick={() => setPopin(false)}>
                    <Cross />
                </button>

                { desktop ? (
                    <Wave className="wave" />
                ) : '' }
            </div>
        </PopinStyle>
    )
}

export default Popin

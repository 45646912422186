import React from 'react'
import ReactDOM from 'react-dom/client'
import FontStyles from './assets/fonts'
import HelpersStyles from './assets/helpers'
import RulesStyles from './assets/rules'
import App from './App'

// Libs styles
import './assets/libs/normalize.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        {/* Common styles */}
        <FontStyles />
        <HelpersStyles />
        <RulesStyles />

        {/* App */}
        <App />
    </React.StrictMode>
);

import styled from 'styled-components';
import { colors } from '../../assets/vars';
import { breakpoint } from '../../assets/mixins';

const PopinStyle = styled.div`
    position: fixed;
    z-index: 10;
    inset: 0;
    padding: 120px 40px 40px;
    transition: transform .25s, opacity .25s;
    opacity: ${p => p.$active ? 1 : 0};
    transform: scale(${p => p.$active ? '1' : '.9'});
    pointer-events: ${p => p.$active ? 'all' : 'none'};

    .inner {
        display: flex;
        position: relative;
        isolation: isolate;
        height: 100%;
        background-color: ${colors.eucalyptus};
        border: 1px solid ${colors.white};

        .left {
            display: flex;
            flex-direction: column;
            flex: 0 0 40%;
            border-right: 1px solid ${colors.white};

            .space {
                flex: 0 0 6vw;
                border-bottom: 1px solid ${colors.white};
            }

            figure {
                position: relative;
                flex: 1;
                overflow: hidden;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 45vw;
                    aspect-ratio: 1 / 1;
                    transform: translate(-50%, -50%) rotate(10deg);

                    ${breakpoint.s`
                        width: 150vw;
                    `}
                }
            }

            ${breakpoint.s`
                flex: 0 0 30%;
                border-right: 0;
                border-bottom: 1px solid ${colors.white};
            `}
        }

        .right {
            position: relative;
            display: flex;
            flex: 1;
            flex-direction: column;

            .number {
                display: flex;
                flex: 0 0 6vw;
                align-items: center;
                border-bottom: 1px solid ${colors.white};
                font-family: "Merriweather", sans-serif;
                font-size: 3vw;
                font-weight: 700;
                font-style: italic;
                padding: 0 4vw;

                ${breakpoint.s`
                    flex: 0 0 auto;
                    padding: 10px 20px;
                    font-size: 3rem;
                    border-bottom: 0;
                `}
            }

            .title {
                padding: 2vw 4vw 1vw;
                font-size: 2.5rem;
                font-weight: 700;
                font-style: italic;
                text-transform: uppercase;
                border-bottom: 1px solid ${colors.white};

                ${breakpoint.s`
                    flex: 0 0 auto;
                    padding: 0 20px 10px;
                    font-size: 1.5rem;
                `}
            }

            .description {
                padding: 3vw 4vw;
                overflow-y: auto;

                strong {
                    font-weight: 700;
                }

                ul {
                    margin-top: 5px;

                    li {
                        position: relative;
                        padding-left: 20px;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 7px;
                            left: 0;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: ${colors.white};
                        }
                    }
                }

                &__buttons {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px 20px;
                    margin-top: 20px;
                }

                ${breakpoint.s`
                    padding: 20px;
                `}
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2.5vw;
                background: linear-gradient(to bottom, transparent, ${colors.eucalyptus});
                pointer-events: none;
            }

            ${breakpoint.s`
                overflow-y: scroll;
            `}
        }

        .close {
            display: flex;
            position: absolute;
            isolation: isolate;
            top: 0;
            right: 0;
            align-items: center;
            justify-content: center;
            width: 4vw;
            height: 4vw;
            min-width: 50px;
            min-height: 50px;
            border-bottom: 1px solid ${colors.white};
            border-left: 1px solid ${colors.white};
            background-color: ${colors.eucalyptus};
            overflow: hidden;

            svg {
                width: 40%;
                height: 40%;
                transition: fill .2s;
                fill: ${colors.white};

                ${breakpoint.s`
                    width: 18px;
                    height: 18px;
                `}
            }

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                inset: -1px;
                transition: transform .25s;
                transform: translateX(-100%);
                background-color: ${colors.white};
            }

            &:hover {
                svg {
                    fill: ${colors.sherwood};
                }

                &::before {
                    transform: translateX(0);
                }
            }
        }

        .wave {
            position: absolute;
            z-index: -1;
            top: 2vw;
            right: 0;
            left: 0;
            opacity: .3;
            fill: ${colors.white};
        }

        ${breakpoint.s`
            flex-direction: column;
            border: 0;
            border-top: 1px solid ${colors.white};
        `}
    }

    ${breakpoint.s`
        padding: 0;
    `}
`;

export default PopinStyle

import React, { useRef } from 'react';
import Lottie from "lottie-react"
import Header from "../Header"
import LayoutStyle from "./style"
import luge from "../../assets/lotties/luge.json"
import candy from "../../assets/lotties/sucre.json"
import deer from "../../assets/lotties/renne.json"
import { ReactComponent as Ball1 } from "../../assets/svgs/ball-2.svg"
import { ReactComponent as Ball2 } from "../../assets/svgs/ball-1.svg"
import { ReactComponent as Gift } from "../../assets/svgs/gift.svg"
import { ReactComponent as Holly } from "../../assets/svgs/holly.svg"
import { ReactComponent as Bouli } from "../../assets/svgs/bouli.svg"


const Layout = ({ welcome, loading, toggleStart, reducedMotion, children }) => {
    const sledge = useRef(null)
    const desktop = window.innerWidth > 767

    return (
        <LayoutStyle id="app" className="app" $loading={ loading } $welcome={ welcome }>
            <Header />

            { children }

            <div className="grid">
                { desktop ? (
                    <>
                        <div className="grid-line grid-line-1"></div>
                        <div className="grid-line grid-line-2"></div>
                        <div className="grid-line grid-line-3"></div>
                        <div className="grid-line grid-line-4"></div>
                        <div className="grid-line grid-line-5"></div>
                        <div className="grid-line grid-line-6"></div>
                        <div className="grid-line grid-line-7"></div>
                        <div className="grid-line grid-line-8"></div>
                        <div className="grid-line grid-line-9"></div>
                    </>
                ) : '' }

                { ! welcome && desktop ? (
                    <div className="bouli-junior">
                        <Bouli />
                    </div>
                ) : '' }

                { welcome ? (
                    <div className="holly">
                        <Holly />
                    </div>
                ) : '' }

                { welcome && desktop ? (
                    <Lottie className="candy" animationData={ candy } loop={ reducedMotion }></Lottie>
                ) : '' }

                { desktop ? (
                    <div className="balls">
                        <div className="ball ball--1">
                            <Ball1 />
                        </div>
                        <div className="ball ball--2">
                            <Ball2 />
                        </div>
                    </div>
                ) : '' }

                { welcome ? (
                    <div className="intro-text">
                        <span> Chaque jour</span>
                        <span> Une astuce green,</span>
                        <span> un fait marquant ou une info</span>
                        <span> qu'on souhaite partager</span>
                        <span> avec vous !</span>
                    </div>
                ) : '' }

                { welcome ? (
                    <div className="bouli">
                        <Bouli />
                    </div>
                ) : '' }

                { welcome ? (
                    <div className="gift">
                        <Gift />
                    </div>
                ) : '' }

                <Lottie className="deer" animationData={deer} loop={ reducedMotion } />

                <Lottie
                    lottieRef={ sledge }
                    className="luge"
                    animationData={luge}
                    loop={ false }
                    autoplay={ false }
                    onDOMLoaded={() => {
                        setTimeout(() => {
                            sledge.current.play()
                        }, 3000);
                    }}
                    onComplete={() => {
                        if ( ! reducedMotion ) {
                            setTimeout(() => {
                                sledge.current.goToAndPlay(0)
                            }, 10000);
                        }
                    }}
                />

                { welcome ? (
                    <div className="button-wrapper">
                        <button className="btn" onClick={() => toggleStart()}>
                            Découvrir
                        </button>
                    </div>
                ) : '' }
            </div>
        </LayoutStyle>
    )
}

export default Layout
